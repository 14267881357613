<template>
    <layout-default :reference="currentPage.references">
        <div class="flex flex-col justify-center items-center">
            <div class="flex flex-col w-full lg:w-10/12 justify-center items-center">
                <h2>Overview</h2>
                <div class="text-left mt-6 space-y-3" v-html="currentPage.text"></div>
                <router-link
                    :to="{ name: 'Summary', params: {name : name} }"
                    class="button alt my-6">
                    Read summary
                </router-link>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';

export default {
    name: 'Overview',
    mixins: [getData],

    components: {
        LayoutDefault
    },

    data() {
        return {
            pageName: 'overview'
        };
    },

    props: {
        name: String,
    }
};
</script>
