import json from '@/json/data.json';

export default {
    props: {
        name: {
            type: String
        }
    },

    metaInfo: {
        title: 'Explore patient case studies',
        meta: [
            {
                name: 'description',
                content: 'For Healthcare Professionals only - review the patient case study and complete the interactive questionnaire to solidify take away key learnings'
            },
        ]
    },

    data() {
        return {
            personInfo: Object.values(json)
        };
    },

    computed: {
        currentPerson() {
            if (this.name) {
                return this.personInfo.find(person => person.url === this.name);
            } else {
                return false;
            }
        },

        currentPage() {
            if (this.pageName) {
                return this.currentPerson.case_study_page[this.pageName];
            } else {
                return false;
            }
        }
    }
};

