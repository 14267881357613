<template>
    <div>
        <a class="button alt flex order-1" @click="openSkipModal()">
            Skip to overview
        </a>
        <Modal v-if="isActive" @close="closeModal()">
            <template v-slot:icon>
                <Icon name="alert" class="w-8 h-8" />
            </template>
            <template v-slot:title>
                Skip to overview?
            </template>
            <template v-slot:body>
                <div class="text-center">
                    <p>Your progress will not be saved.<br>
                        You can restart the questionnaire at any time.
                    </p>
                </div>
            </template>
            <template v-slot:buttons>
                <div class="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4 mt-12">
                    <button class="alt" aria-label="close" @click="closeModal()">
                        Continue case review
                    </button>
                    <button @click="goToOverviewPage()">
                        Skip to overview
                    </button>
                    <gtag ref="gTag" :category="'SkipToOverview'" :action="name" :label="'Q' + question"></gtag>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Icon from '@/components/Icon';
import Modal from '@/components/Modal.vue';

export default {
    components: {
        Icon,
        Modal
    },

    data() {
        return {
            isActive: false
        };
    },

    props: {
        question: String,
        name: String
    },

    methods: {
        openSkipModal() {
            this.isActive = true;
        },

        closeModal() {
            this.isActive = false;
        },

        goToOverviewPage() {
            this.$refs.gTag.track();
            this.$router.push({
                name: 'Overview',
                params: {
                    name: this.name
                }
            });
        }
    }
};
</script>

