<template>
    <div class="flex flex-col items-center">
        <h2>Treatment option overview</h2>
        <div v-html="currentQuestion.noAnswerFeedback" class="mt-8 text-left space-y-3"></div>
    </div>
</template>

<script>
import getData from '@/mixins/getData.js';

export default {
    name: 'NoAnswer',

    mixins: [getData],

    props: {
        name: String,
        currentQuestion: Object
    }
};
</script>
