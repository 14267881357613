<template>
    <div>
        <a @click="openReferenceModal" class="flex text-right text-red text-sm font-semibold uppercase cursor-pointer w-40 md:w-auto hover:text-dark-red">
            References &amp; Abbreviations
        </a>
        <Modal v-if="isActive" @close="closeModal()">
            <template v-slot:subTitle>
                References &amp; Abbreviations
            </template>
            <template v-slot:body>
                <div class="flex items-start">
                    <slot></slot>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
    components: {
        Modal
    },

    data() {
        return {
            isActive: false
        };
    },

    methods: {
        openReferenceModal() {
            this.isActive = true;
            document.body.classList.add('overflow-hidden');
        },

        closeModal() {
            this.isActive = false;
            document.body.classList.remove('overflow-hidden');
        }
    }
};
</script>

