<template>
    <main class="container-xl flex flex-col flex-1 flex-wrap pb-10 mt-10 md:mt-16 h-full px-6 md:px-14 w-full">
        <slot />
    </main>
</template>

<script>
export default {
    name: 'LayoutDefault',

    props: {
        reference : String
    },

    mounted() {
        this.$root.$emit('refLink', this.reference);
    }
};
</script>
