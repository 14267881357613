<template>
    <div class="flex flex-col items-center">
        <Icon name="tick" class="w-10 h-10" />
        <h2>Correct</h2>
        <div v-html="currentQuestion.correctAnswerFeedback" class="mt-8 text-left space-y-3 feedback"></div>
    </div>
</template>

<script>
import getData from '@/mixins/getData.js';
import Icon from '@/components/Icon';

export default {
    name: 'Correct',

    components: {
        Icon
    },

    mixins: [getData],

    props: {
        name: String,
        currentQuestion: Object
    }
};
</script>
