<template>
    <div class="flex px-6 md:px-14 w-full">
        <header class="flex justify-between items-center border-b-2 border-grey w-full py-3 md:py-5">
            <home-modal>
                <img src="@/images/csl-behring-logo.svg" alt="CSL Hae hub" class="max-h-10 md:max-h-full" />
            </home-modal>
            <reference-modal v-if="reference">
                <div v-html="reference" class="space-y-3"></div>
            </reference-modal>
        </header>
    </div>
</template>

<script>
import ReferenceModal from '@/components/ReferenceModal';
import HomeModal from '@/components/HomeModal';

export default {
    components: {
        ReferenceModal,
        HomeModal
    },

    data() {
        return {
            reference: ''
        };

    },

    mounted() {
        this.$root.$on('refLink', data => {
            this.reference = data;
        });
    }
};
</script>
