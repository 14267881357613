<template>
    <layout-default :reference="currentPage.references">
        <div class="flex flex-col lg:flex-row sm:-mx-3 w-full mb-20">
            <div class="flex justify-start items-center circle-bg sm:my-3 sm:px-3 w-full lg:w-3/5 xl:w-2/5">
                <div class="person-img-container summary-before w-10/12 md:w-4/5 xl:w-3/5">
                    <div class="block md:flex justify-center">
                        <p class="text-red tracking-widest font-bold text-xxs transform -rotate-90 flex justify-start self-end pb-4 absolute bottom-5 left-0">AFTER</p>
                        <img :src="require(`@/images/${name}-after.png`)"
                             :alt="currentPage.altTextAfter"
                             :title="currentPage.altTextAfter"
                             class="relative"
                        />
                    </div>
                    <div class="person-stats-alt w-2/5 md:w-40 lg:w-6/12 xl:w-7/12">
                        <div class="block md:flex justify-center">
                            <p class="text-red tracking-widest font-bold text-xxs transform -rotate-90 flex justify-start self-end pb-4 absolute bottom-5 left-0">BEFORE</p>
                            <img :src="require(`@/images/${name}-half.png`)"
                                 :alt="currentPage.altTextBefore"
                                 :title="currentPage.altTextBefore"
                                 class="summary-after relative p-2 md:p-0"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm:my-3 sm:px-3 ml-0 lg:ml-30 w-full lg:w-2/5 xl:w-1/2 flex flex-col items-center md:items-start mt-28 sm:mt-32 md:mt-28">
                <h2>Summary</h2>
                <div class="text-left mt-3 space-y-3" v-html="currentPage.text"></div>
                <router-link
                    :to="{ name: 'Key Learnings', params: {name : name} }"
                    class="button alt mt-6">
                    Key learnings
                </router-link>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';

export default {
    name: 'Summary',
    mixins: [getData],

    components: {
        LayoutDefault
    },

    data() {
        return {
            pageName: 'summary'
        };
    },

    props: {
        name: String,
    }
};
</script>
