<template>
    <div class="min-h-screen p-6 flex flex-col justify-center items-center">
        <div class="flex justify-center flex-col max-w-xlg">
            <h1>Ui Kit</h1>
            <h2>Colours</h2>
            <div class="flex flex-row space-x-2">
                <div class="bg-red w-32 h-32">red</div>
                <div class="bg-dark-red w-32 h-32">dark-red</div>
                <div class="bg-light-black text-white w-32 h-32">light-black</div>
                <div class="bg-grey-dark w-32 h-32">grey-dark</div>
                <div class="bg-grey w-32 h-32">grey</div>
                <div class="bg-grey-light w-32 h-32">grey-light</div>
                <div class="bg-grey-lightest w-32 h-32">grey-lightest</div>
            </div>
            <h2>Buttons</h2>
            <div class="button-wrapper flex flex-row space-x-2">
                <button>Button</button>
                <button class="alt">Button</button>
            </div>
            <h2>Icons</h2>
            <div class="flex flex-col space-x-2">
                <Icon name="airplane" class="w-6 h-6 mr-1" />airplane
                <Icon name="alert" class="w-6 h-6 mr-1" />alert
                <Icon name="all" class="w-6 h-6 mr-1" />all
                <Icon name="arrow" class="w-6 h-6 mr-1" />arrow
                <Icon name="close" class="w-6 h-6 mr-1" />close
                <Icon name="comment" class="w-6 h-6 mr-1" />comment
                <Icon name="deep-vein-thrombosis" class="w-6 h-6 mr-1" />deep-vein-thrombosis
                <Icon name="device-related-complications" class="w-6 h-6 mr-1" />device-related-complications
                <Icon name="diet" class="w-6 h-6 mr-1" />diet
                <Icon name="exercise" class="w-6 h-6 mr-1" />exercise
                <Icon name="infection" class="w-6 h-6 mr-1" />infection
                <Icon name="stress" class="w-6 h-6 mr-1" />stress
                <Icon name="tick" class="w-6 h-6 mr-1" />tick
            </div>
            <h2>Forms</h2>
            <form>
                <div>
                    <input id="radio-1" class="question-radio" name="radio-group" type="radio" checked>
                    <label for="radio-1" class="question-radio-label">First Choice</label>
                </div>
                <div>
                    <input id="radio-2" class="question-radio" name="radio-group" type="radio">
                    <label for="radio-2" class="question-radio-label">Second Choice</label>
                </div>
                <div>
                    <input id="radio-3" class="question-radio" name="radio-group" type="radio">
                    <label for="radio-3" class="question-radio-label">Third Choice</label>
                </div>
            </form>

        </div>
    </div>
</template>

<script>

import Icon from '@/components/Icon.vue';

export default {
    name: 'UI Kit',
    components: {
        Icon
    }
};
</script>
