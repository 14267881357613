<template>
    <layout-default :reference="currentPage.references">
        <div class="flex flex-col md:flex-row justify-center w-full">
            <home-modal>
                <div class="flex absolute left-6 md:left-12 top-24 md:top-32 uppercase text-sm font-bold opacity-60 tracking-wider">
                    <Icon name="arrow" class="flex self-center transform -rotate-180 w-4 h-4 mr-2" />
                    Back to start
                </div>
            </home-modal>
            <div class="flex flex-col md:flex-row justify-center w-full lg:w-11/12 py-8 pb-20 sm:mt-0">
                <div class="sm:my-3 sm:px-3 w-full md:w-1/2 xl:w-1/3">
                    <div class="person-img-container">
                        <img :src="require(`@/images/${name}-half.png`)" :alt="currentPerson.altImageText" />
                        <div class="person-stats text-left">
                            <h5 class="font-semibold">{{ currentPerson.title }}</h5>
                            <div class="text-left facts text-sm mt-2" v-html="currentPerson.description"></div>
                        </div>
                    </div>
                </div>
                <div class="my-3 px-0 sm:px-3 ml-0 sm:ml-12 w-full lg:w-2/3 flex flex-col items-center md:items-start lg:mt-20">
                    <h2>Treatment history</h2>
                    <div class="text-left space-y-5 mt-5" v-html="currentPage.text"></div>
                    <router-link
                        :to="{ name: 'Reason for presentation', params: {name : name} }"
                        class="button alt mt-10">
                        Reason for presentation
                    </router-link>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';
import HomeModal from '@/components/HomeModal';
import Icon from '@/components/Icon';

export default {
    name: 'PersonTreatmentHistory',
    mixins: [getData],

    components: {
        LayoutDefault,
        HomeModal,
        Icon
    },

    data() {
        return {
            pageName: 'treatmentHistory'
        };
    },

    props: {
        name: String,
    }
};
</script>

<style>
.facts ul{
    @apply space-y-1;
}
</style>
