<template>
    <svg class="icon" :class="className">
        <use :xlink:href="'#icon-' + name" />
    </svg>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        name: String,
        className: String
    }
};
</script>
