<template>
    <div>
        <a :href="href" @click.prevent="openExternalLink" class="external-link">
            <slot></slot>
        </a>
        <Modal v-if="isActive" @close="closeModal()">
            <template v-slot:icon>
                <Icon name="alert" class="w-8 h-8" />
            </template>
            <template v-slot:title>
                You are now leaving the site
            </template>
            <template v-slot:body>
                <div class="text-center">
                    <p>This link opens another website that is not under the review or control of CSL Behring and, as such, the company does not endorse the content, its accuracy, or any practices or standards contained within it.</p>
                </div>
            </template>
            <template v-slot:buttons>
                <div class="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4 mt-12">
                    <a class="button alt" aria-label="close" @click="closeModal()">Cancel</a>
                    <a :href="href" class="button" target="_blank" rel="noopener noreferrer" @click="closeModal()">Continue to link</a>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Icon from '@/components/Icon';
import Modal from '@/components/Modal.vue';

export default {
    components: {
        Icon,
        Modal
    },

    props: {
        showModal: Boolean,
        href: String
    },

    data() {
        return {
            isActive: false
        };
    },

    methods: {
        closeModal() {
            this.isActive = false;
        },

        openExternalLink() {
            this.isActive = true;
        }
    }
};
</script>

