<template>
    <layout-default :reference="currentQuestion.References ? currentQuestion.References.referenceQuestion : ''">
        <div class="flex flex-col items-center w-full text-center">
            <image-select
                v-if="currentQuestion.questionType == 'imageSelect'"
                :question="currentQuestion"
                :total-questions="totalQuestions"
                :name="name"
                :question-id="questionId"
            ></image-select>
            <conversation
                v-if="currentQuestion.questionType == 'chatSelect'"
                :question="currentQuestion"
                :total-questions="totalQuestions"
                :name="name"
                :question-id="questionId"
                :alt-tag="currentPerson.altImageText"
            ></conversation>
            <basic-select
                v-if="currentQuestion.questionType == 'basicSelect'"
                :question="currentQuestion"
                :total-questions="totalQuestions"
                :name="name"
                :question-id="questionId"
            ></basic-select>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';
import quiz from '@/mixins/quiz.js';
import ImageSelect from '@/Pages/case-study/questions/ImageSelect';
import Conversation from '@/Pages/case-study/questions/Conversation';
import BasicSelect from '@/Pages/case-study/questions/BasicSelect';

export default {
    name: 'Question',
    mixins: [getData, quiz],

    components: {
        LayoutDefault,
        ImageSelect,
        Conversation,
        BasicSelect
    },

    props: {
        name: String,
        questionId: String
    }
};
</script>

