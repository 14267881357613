export default {
    props: {
        name: String,
        questionId: String
    },

    computed: {
        currentQuestion() {
            if (this.questionId) {
                return this.currentPerson.questions.find((question,index) => index + 1 == this.questionId);
            } else {
                return false;
            }
        },

        totalQuestions() {
            return this.currentPerson.questions.length;
        }
    },

    methods: {
        nextQuestion() {
            let questionNumber = parseInt(this.questionId);
            questionNumber++;
            this.$router.push({name: 'Question', params: { name : this.name, questionId : questionNumber.toString() }});
        },

        goToOverview() {
            this.$router.push({name: 'Overview', params: { name : this.name }});
        },

        goToSummary() {
            this.$router.push({name: 'Summary', params: { name : this.name }});
        },

        goToAnswerPage() {
            this.$router.push({name: 'Answer', params: { name : this.name }});
        },

        goToKeyLearningsPage() {
            this.$router.push({name: 'Key Learnings', params: { name : this.name }});
        }

    }
};

