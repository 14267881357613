<template>
    <layout-default :reference="currentQuestion.References ? currentQuestion.References.referenceFeedback : ''">
        <div class="flex flex-col justify-center items-center w-full">
            <div class="flex items-center flex-col w-full lg:w-8/12 px-4 text-center">
                <correct
                    v-if="correctAnswer && userResponse == correctAnswer.id"
                    :current-question="currentQuestion"
                    :question-id="questionId"
                ></correct>
                <incorrect
                    v-if="incorrectAnswer && userResponse != correctAnswer.id"
                    :current-question="currentQuestion"
                    :question-id="questionId"
                ></incorrect>
                <no-answer
                    v-if="noAnswer"
                    :current-question="currentQuestion"
                    :question-id="questionId"
                ></no-answer>
                <div
                    class="button mt-10"
                    @click="nextQuestion()"
                    v-if="questionId < totalQuestions"
                >
                    Next question
                </div>
                <div
                    class="button alt mt-10"
                    @click="goToSummary()"
                    v-if="questionId >= totalQuestions"
                >
                    Finish
                </div>
            </div>
        </div>
        <gtag ref="gTag" :category="name" :action="'Question' + questionId" :label="'A' + answerPosition"></gtag>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';
import quiz from '@/mixins/quiz.js';
import Correct from '@/Pages/case-study/questions/Correct';
import Incorrect from '@/Pages/case-study/questions/Incorrect';
import NoAnswer from '@/Pages/case-study/questions/NoAnswer';

export default {
    name: 'Result',
    mixins: [getData, quiz],

    components: {
        LayoutDefault,
        Correct,
        Incorrect,
        NoAnswer,
    },

    props: {
        name: String,
        questionId: String,
        userResponse: Number,
        answerPosition: Number
    },

    mounted() {
        this.$refs.gTag.track();
    },

    computed: {
        correctAnswer() {
            return this.currentQuestion.answersComponent.find(
                (o) => o.answerIsCorrect == true
            );
        },

        incorrectAnswer() {
            return this.currentQuestion.answersComponent.find(
                (o) => o.answerIsCorrect == false
            );
        },

        noAnswer() {
            return this.currentQuestion.answersComponent.find(
                (o) => o.answerIsCorrect == null
            );
        }
    },
};
</script>

<style lang="scss">
.feedback ul {
    @apply flex flex-col flex-wrap text-left;

    &.list {
        @apply h-full md:h-72 lg:h-52;

        li:first-child {
            @apply mt-4;
        }
    }

}
</style>
