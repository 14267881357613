<template>
    <div id="app">
        <IconSet />
        <div class="flex flex-col relative min-h-screen m-0 w-full">
            <HeaderSection />
            <transition name="fade" mode="out-in" duration="200">
                <router-view />
            </transition>
            <FooterSection />
        </div>
    </div>
</template>

<script>
import IconSet from '@/components/IconSet.vue';
import HeaderSection from '@/components/Header';
import FooterSection from '@/components/Footer';

export default {
    components: {
        IconSet,
        HeaderSection,
        FooterSection
    }
};
</script>

<style lang="scss">
@import './src/css/app';
</style>
