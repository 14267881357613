export default {
    props: {
        question: Object,
        totalQuestions: Number,
        name: String,
        questionId: String
    },

    data() {
        return {
            formResults: '',
            postionNumber: 0,
            isHovering: false
        };
    },

    computed: {
        answerSelected() {
            return !this.formResults ? true : false;
        }
    },

    methods: {
        submitQuestion() {
            this.$router.push({
                name: 'Result',
                params: {
                    name: this.name,
                    questionId: this.questionId,
                    userResponse: this.formResults,
                    answerPosition: this.postionNumber
                },
            });
        },

        skipToSummary() {
            this.$emit('go-to-summary');
        },

        orderNumberAnswered(orderNumber) {
            this.postionNumber = orderNumber;
        }
    }
};
