<template>
    <div class="flex flex-wrap lg:space-x-10">
        <div v-if="showDialogue && isMobile" class="absolute w-full h-full bg-grey-dark bg-opacity-40 backdrop-filter backdrop-blur-sm top-0 left-0"></div>
        <div class="w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12 px-0 md:px-5">
            <transition name="fade">
                <div
                    class="dialogue border rounded-2xl border-grey-dark shadow-chat block"
                    :class="{ 'hidden lg:block': !showDialogue, 'mobile-chat': isMobile }"
                    v-if="showDialogue || !isMobile"
                >
                    <div
                        class="flex bg-grey h-20 rounded-t-2xl relative py-5 pr-5 pl-28 font-semibold"
                        @click="toggleDialogue()"
                    >
                        <img
                            :src="require(`@/images/${name}-${profileImageOption}.png`)"
                            :alt="altTag"
                            class="bg-white absolute w-24 h-24 border border-grey-dark rounded-md inset-x-6 -inset-y-7"
                        />
                        <div v-html="question.Blurb.intro" class="blurb"></div>
                        <Icon name="arrow" class="absolute right-4 top-5 w-5 h-5 transform rotate-90 fill-grey-darkest block md:hidden" />
                    </div>
                    <div class="chat-body py-5 space-y-4">
                        <div
                            v-for="(chat) in question.Blurb.chatDialogue"
                            :key="chat.id"
                            class="px-6 flex flex-col mt-2"
                        >
                            <div v-if="chat.position == 'right'" class="person-1">
                                <div class="name"
                                     v-if="removeDuplicateName(chat.extraClass)">
                                    You
                                </div>
                                <div
                                    v-if="chat.extraClass != 'notification' || chat.extraClass != 'profile-image'"
                                    class="msg text-left"
                                    :class="chat.extraClass"
                                    v-html="chat.text"
                                >
                                </div>
                                <div
                                    v-if="chat.extraClass == 'notification'"
                                    class="notification"
                                    v-html="chat.text"
                                >
                                </div>
                                <div
                                    v-if="chat.extraClass == 'profile-image'"
                                >
                                    <img :src="require(`@/images/${name}-${profileImageOption}.png`)"
                                         alt="An ongoing conversation between a patient and healthcare professional"
                                         class="w-24 h-24 border border-grey rounded-md"
                                    />
                                </div>
                            </div>
                            <div v-if="chat.position == 'left'" class="person-2">
                                <div
                                    class="name"
                                    v-if="removeDuplicateName(chat.extraClass)"
                                >
                                    {{ name }}
                                </div>
                                <div class="msg text-left"
                                     :class="chat.extraClass"
                                     v-html="chat.text"
                                     v-if="chat.extraClass != 'notification' || chat.extraClass != 'profile-image'"
                                >
                                </div>
                                <div
                                    v-if="chat.extraClass == 'profile-image'"
                                >
                                    <img :src="require(`@/images/${name}-${profileImageOption}.png`)"
                                         alt="An ongoing conversation between a patient and healthcare professional"
                                         class="w-24 h-24 border border-grey rounded-md"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="w-full lg:w-5/12 xl:w-6/12 2xl:w-7/12">
            <h2 class="text-center md:text-left">
                Question {{ questionId }}
                <span class="text-base font-normal">of {{ totalQuestions }}</span>
            </h2>
            <div class="font-semibold text-center md:text-left my-3" v-html="question.questionText"></div>
            <div class="answer-otions">
                <div v-for="(answer, index) in question.answersComponent"
                     :key="answer.id"
                     class="answer-select">
                    <input
                        :id="answer.id"
                        :value="answer.id"
                        class="question-radio hidden"
                        name="radio-group"
                        type="radio"
                        v-model="formResults"
                        @input="orderNumberAnswered(index+1)"
                    />
                    <label
                        :for="answer.id"
                        class="question-radio-label"
                        v-html="answer.answerText"
                        @mouseover="isHovering = true"
                        @mouseout="isHovering = false"
                        :class="{'hover-label': isHovering }"

                    ></label>

                </div>
            </div>
            <div class="flex justify-center md:justify-start space-x-4 mt-12">
                <skip-modal :question="questionId" :name="name"></skip-modal>
                <button
                    class="submit order-2"
                    :class="{ 'cursor-not-allowed': answerSelected }"
                    @click="submitQuestion()"
                    :disabled="answerSelected"
                >
                    Submit
                </button>
            </div>

        </div>
        <div
            class="flex bg-grey h-20 rounded-t-2xl fixed bottom-0 left-0 py-5 z-50 w-full font-semibold block border-t border-grey-dark lg:hidden"
            @click="toggleDialogue()">
            <div class="flex justify-start w-40 h-20 relative">
                <img
                    :src="require(`@/images/${name}-${profileImageOption}.png`)"
                    :alt="altTag"
                    class="bg-white absolute w-20 h-20 border border-grey-dark rounded-md inset-x-5 -inset-y-8"
                />
                <span class="flex h-3 w-3 relative left-20 -top-7">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red"></span>
                </span>
            </div>
            <div class="blurb text-sm text-left pl-0 ml-0" v-html="question.Blurb.intro"></div>
        </div>
    </div>
</template>

<script>
import question from '@/mixins/question.js';
import SkipModal from '@/components/SkipModal';
import Icon from '@/components/Icon';

export default {
    components: {
        SkipModal,
        Icon
    },

    mixins: [question],

    props: {
        altTag: String
    },

    data() {
        return {
            showDialogue: false,
            window: {
                width: 0
            }
        };
    },

    computed: {
        isMobile() {
            return this.window.width <= 1023 ? true : false;
        },

        profileImageOption() {
            if (this.question.Blurb.profileImg) {
                return 'new-profile';
            } else {
                return 'portrait';
            }
        }
    },

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },

    beforeCreate() {
        document.body.classList.remove('questions-bg');
        document.body.classList.add('case-studies-other-bg');
    },

    methods: {
        removeDuplicateName(className) {
            if (!['repeat', 'notification', 'profile-image'].includes(className)) return true;
        },

        handleResize() {
            this.window.width = window.innerWidth;
        },

        toggleDialogue() {
            if (this.isMobile) {
                this.showDialogue = !this.showDialogue;
                document.body.classList.add('overflow-hidden');
            }

            if (!this.showDialogue) {
                document.body.classList.remove('overflow-hidden');
            }
        }
    }

};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
