<template>
    <layout-default :reference="references">
        <div class="flex flex-col lg:flex-row justify-between">
            <div class="flex flex-col items-start justify-center w-full lg:w-5/12 ml-0 xl:ml-20">
                <div class="space-y-3">
                    <h2><span class="whitespace-normal md:whitespace-nowrap">Hereditary Angioedema</span> interactive case study tool</h2>
                    <p class="font-bold">Explore the case studies and test your knowledge and understanding of managing patients with HAE</p>
                    <p>Berinert<sup>&reg;</sup> 500/1500 (C1-INH [IV]) is licensed for the treatment and pre-procedure prevention of acute episodes in HAE type 1 and 2.<sup>1</sup></p>
                    <p>Berinert<sup>&reg;</sup> 2000/3000 (C1-INH [SC]) is licensed for the prevention of recurrent HAE attacks in adolescent and adult patients with C1-INH deficiency.<sup>2</sup></p>
                </div>
                <router-link
                    :to="{ name: 'Case Studies'}"
                    class="button mt-6">
                    Explore case studies
                </router-link>
            </div>
            <div class="block md:flex justify-end items-center w-full lg:w-7/12 md:mr-0 xl:mr-12">
                <img src="@/images/users.png" class="w-full md:w-4/5 mt-12 sm:mt-0 h-auto" />
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';

export default {
    name: 'Home',

    metaInfo: {
        title: 'Hereditary angioedema interactive case study tool',
        meta: [
            {
                name: 'description',
                content: 'For Healthcare Professionals only - an interactive case study tool to support continued education in identifying and treating patients with Hereditary Angioedema'
            },
        ]
    },

    components: {
        LayoutDefault
    },

    data() {
        return {
            references: '<p>CSL Behring does not suggest or recommend the use of its products in any way other than as described in the Prescribing Information.</p><p>C1-INH, C1-esterase inhibitor; HAE, hereditary angioedema; IV, intravenous; SC, subcutaneous.</p><p>1. CSL Behring. Berinert<sup>&reg;</sup> 500/1500 Summary of Product Characteristics. 2018; 2. CSL Behring. Berinert<sup>&reg;</sup> 2000/3000 Summary of Product Characteristics. 2020.</p>'
        };
    }
};
</script>
