<template>
    <div>
        <h2>
            Question {{ questionId }}
            <span class="text-base font-normal">of {{ totalQuestions }}</span>
        </h2>
        <div class="font-semibold text-center max-w-3xl mt-5" v-html="question.questionText"></div>
        <div class="flex flex-row flex-wrap justify-center text-center space-x-2 md:space-x-4 answer-otions">
            <div v-for="(answer, index) in question.answersComponent" :key="answer.id" class="mt-5 answer-select">

                <div v-if="answer.icon">
                    <input :id="answer.id" :value="answer.id" class="question-radio image-select hidden" name="radio-group" type="radio" v-model="formResults">
                    <label :for="answer.id"
                           class="question-image-label flex items-center flex-col justify-center md:justify-start border-2 border-grey rounded-2xl bg-off-white md:pt-12 w-36 md:w-48 h-36 md:h-48 checked:opacity-100 checked:bg-grey"
                           @mouseover="isHovering = true"
                           @mouseout="isHovering = false"
                           :class="{'hover-label': isHovering }"
                    >
                        <Icon :name="answer.icon" class="w-10 h-10 inline-block mb-3" />
                        <p v-html="answer.answerText" class="md:px-3"></p>
                    </label>
                </div>
                <div v-else>
                    <input :id="answer.id"
                           :value="answer.id"
                           class="question-radio hidden"
                           name="radio-group"
                           type="radio"
                           v-model="formResults"
                           @input="orderNumberAnswered(index+1)"
                    >
                    <label :for="answer.id"
                           class="question-radio-label img-select flex-col items-center justify-center md:justify-start md:pt-12 w-36 md:w-48 h-36 md:h-48"
                           v-html="answer.answerText"
                           @mouseover="isHovering = true"
                           @mouseout="isHovering = false"
                           :class="{'hover-label': isHovering }"
                    >
                    </label>
                </div>
            </div>
        </div>
        <div class="flex justify-center space-x-4 mt-12">
            <skip-modal :question="questionId" :name="name"></skip-modal>
            <button
                class="submit order-2"
                :class="{ 'cursor-not-allowed': answerSelected }"
                @click="submitQuestion()"
                :disabled="answerSelected"
            >
                Submit
            </button>
        </div>
    </div>
</template>

<script>
import question from '@/mixins/question.js';
import Icon from '@/components/Icon';
import SkipModal from '@/components/SkipModal';

export default {
    components: {
        Icon,
        SkipModal
    },
    mixins: [question]
};
</script>
