<template>
    <div class="flex flex-col items-center">
        <Icon name="close" class="w-10 h-10" />
        <h2>That’s not quite right</h2>
        <div v-html="currentQuestion.incorrectAnswerFeedback" class="mt-8 space-y-3 text-left feedback"></div>
    </div>
</template>

<script>
import getData from '@/mixins/getData.js';
import Icon from '@/components/Icon';
export default {
    name: 'Incorrect',

    components: {
        Icon
    },

    mixins: [getData],

    props: {
        name: String,
        currentQuestion: Object
    }
};
</script>
