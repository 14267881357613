<template>
    <layout-default>
        <div class="flex flex-col justify-start w-full md:w-11/12 lg:w-8/12 xl:w-7/12 space-y-3 ml-0 lg:ml-24">
            <h1>Welcome</h1>
            <p>The information in this interactive digital tool is intended for healthcare professionals attending the EAACI Congress 2021 only. By clicking on the link below, you are confirming that you are a registered healthcare professional.<p>
            <p>The information in this interactive digital tool has been developed for an EU audience. Please note that this tool contains information on specific products and indications. The Summary of Product Characteristics (SmPC) provided here may vary depending on local approval in each country. For the purposes of the EAACI Congress 2021, best efforts were undertaken by CSL Behring and the congress sponsors to ensure compliance with the EFPIA Code of Practice in line with the Joint Guidelines (IFPMA, EFPIA and PhRMA). However, you should review your local Prescribing Information and/or SmPC and consult directly with your local CSL Behring affiliate to address any questions.<p>
            <p>Registration is not required to use this interactive tool and your personal information will not be collected. </p>
            <p class="text-sm">CSL Behring GmbH, PO Box 12 30, 35002 Marburg, Germany<br>
                Date of preparation: June 2021
            </p>

            <div class="flex flex-col items-start pt-7">
                <label for="confirmHCP" class="flex justify-start items-start">
                    <div class="bg-white border rounded border-grey-dark w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-grey-darkest">
                        <input type="checkbox" class="opacity-0 absolute" id="confirmHCP" v-model="confirmHCP">
                        <Icon name="tick" class="hidden w-3 h-3 text-red pointer-events-none" />
                    </div>
                    <div class="select-none text-light-black">I confirm I am a healthcare professional and have read and agree to the above.</div>
                </label>

                <button
                    @click="confirmProfessional()"
                    :disabled="!confirmHCP"
                    :class="{ 'cursor-not-allowed': !confirmHCP }"
                    class="mt-10"
                >
                    Continue
                </button>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import Icon from '@/components/Icon';

export default {
    name: 'HomeConfirmation',

    metaInfo: {
        title: 'Welcome - HCP confirmation',
        meta: [
            {
                name: 'description',
                content: 'For Healthcare Professionals only - information in this interactive digital case study tool has been developed for an EU audience with confirmation required to enter'
            },
        ]
    },

    components: {
        LayoutDefault,
        Icon
    },

    data() {
        return {
            confirmHCP: false
        };
    },

    methods: {
        confirmProfessional() {
            this.patientConfirmed = true;
            this.$cookies.set('cslProfessionalConfirmed', true, 0);
            this.$router.push({ name: 'Welcome'});
        },
    }
};
</script>

<style scoped>
input:checked + svg {
    display: block;
}
</style>
