import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeConfirmation from '@/Pages/HomeConfirmation';
import UIKit from '@/Pages/UIKit';
import NotFound from '@/Pages/NotFound';
import Welcome from '@/Pages/Welcome';
import CaseStudies from '@/Pages/CaseStudies';
import PersonTreatmentHistory from '@/Pages/case-study/PersonTreatmentHistory';
import PersonReasonForPresentation from '@/Pages/case-study/PersonReasonForPresentation';
import Overview from '@/Pages/case-study/Overview';
import Summary from '@/Pages/case-study/Summary';
import KeyLearnings from '@/Pages/case-study/KeyLearnings';
import Question from '@/Pages/case-study/Question';
import Result from '@/Pages/case-study/Result';
import multiguard from 'vue-router-multiguard';

Vue.use(VueRouter);

const people = ['christine', 'helen', 'jack', 'maria'];

const checkValidUser = function(to, from, next) {
    if (!people.includes(to.params.name)) {
        next({ name: 'Not Found'});
    } else {
        next();
    }
};

const checkValidQuestion = function(to, from, next) {
    if (/^(?=.*[a-zA-Z])/.test(to.params.questionId) || (/^(?=.*[a-zA-Z])/.test(to.params.questionId) && parseInt(to.params.questionId) > 8)) {
        next({ name: 'Not Found'});
    } else {
        next();
    }
};

const baseRoutes = [
    {
        path: '/',
        name: 'HomeConfirmation',
        component: HomeConfirmation
    }, {
        path: '/ui-kit',
        name: 'UI Kit',
        component: UIKit
    }, {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome
    }, {
        path: '/case-studies',
        name: 'Case Studies',
        component: CaseStudies
    }, {
        path: '/:name',
        name: 'Treatment History',
        component: PersonTreatmentHistory,
        props: true,
        beforeEnter: multiguard([checkValidUser])
    }, {
        path: '/:name/reason-for-presentation',
        name: 'Reason for presentation',
        component: PersonReasonForPresentation,
        props: true,
        beforeEnter: multiguard([checkValidUser])
    }, {
        path: '/:name/overview',
        name: 'Overview',
        component: Overview,
        props: true,
        beforeEnter: multiguard([checkValidUser])
    }, {
        path: '/:name/summary',
        name: 'Summary',
        component: Summary,
        props: true,
        beforeEnter: multiguard([checkValidUser])
    }, {
        path: '/:name/key-learnings',
        name: 'Key Learnings',
        component: KeyLearnings,
        props: true,
        beforeEnter: multiguard([checkValidUser])
    }, {
        path: '/:name/:questionId',
        name: 'Question',
        component: Question,
        props: true,
        beforeEnter: multiguard([checkValidUser, checkValidQuestion])

    }, {
        path: '/:name/:questionId/result',
        name: 'Result',
        component: Result,
        props: true,
        beforeEnter: multiguard([checkValidUser, checkValidQuestion])
    }, {
        path: '*',
        name: 'Not Found',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    routes: baseRoutes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

export default router;
