<template>
    <layout-default :reference="currentPage.references">
        <div class="flex flex-col justify-center items-center">
            <div class="flex flex-col w-full lg:w-10/12 justify-center items-center">
                <h2>Key learnings</h2>
                <div class="mt-3 text-center" v-html="currentPage.subText"></div>
                <div class="flex flex-col md:flex-row mt-3">
                    <div v-for="(stage, stageIndex) in currentPage.stages"
                         :key="stage.id"
                         class="flex flex-col items-center text-center p-4 m-2 max-w-xs">
                        <div class="rounded-circle border-2 border-red text-red text-center w-8 h-8 leading-8 font-semibold">
                            {{ stageIndex + 1 }}
                        </div>
                        <p class="mt-4">{{stage.title}}</p>
                        <p v-if="stage.text" class="mt-2 text-sm">{{stage.text}}</p>
                    </div>
                </div>

                <div v-if="currentPage.text"
                     v-html="currentPage.text"
                     class="text-center">
                </div>
                <router-link
                    :to="{ name: 'Case Studies' }"
                    class="button alt mt-10">
                    Explore more case studies
                </router-link>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';

export default {
    name: 'KeyLearnings',
    mixins: [getData],
    components: {
        LayoutDefault
    },

    data() {
        return {
            pageName: 'keyLearnings'
        };
    },

    props: {
        name: String
    }
};
</script>
