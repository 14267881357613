<template>
    <layout-default :reference="references">
        <div class="flex flex-col justify-center w-full">
            <h2 class="my-0 mx-auto text-center max-w-xs md:max-w-5xl">You see four patients in your clinic, each patient was referred for attacks of angioedema</h2>
            <p class="text-center mt-4">Select a patient to explore their symptoms</p>
            <div class="container my-0 mx-auto grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10 ">
                <div
                    v-for="(person, index) in personInfo"
                    :key="person.id"
                    @click="goToUser(person.url)"
                    @mouseleave="userSelect = null"
                    @mouseover="userSelect = index"
                    :class="{ 'sm:opacity-100' : userSelect == index }"
                    class="case-study flex flex-col lg:w-auto border rounded-2xl border-grey bg-white cursor-pointer transform origin-bottom hover:transform hover:scale-y-105"
                >
                    <div class="flex flex-col md:flex-auto lg:flex-1 opacity-100 lg:opacity-40 transition-opacity duration-200 ease-out hover:transform hover:opacity-100">
                        <div class="flex flex-row md:flex-col items-center border-b border-grey pb-4">
                            <div class="bg-grey rounded-xl overflow-hidden mt-5 ml-4 md:ml-0">
                                <img :src="require(`@/images/${person.url}-portrait.png`)" :alt="person.altImageText" class="w-28 h-28" />
                            </div>
                            <div class="flex flex-col items-start md:items-center ml-4 md:ml-0">
                                <p class="text-lg font-semibold mt-3">{{ person.title }}</p>
                                <p class="text-sm">{{ person.sex }} / {{ person.age }} years old</p>
                            </div>
                        </div>
                        <div class="p-4">
                            <p class="font-semibold">Key features:</p>
                            <ul class="text-sm mt-3 space-y-2">
                                <li v-for="feature in person.features" :key="feature.id">
                                    {{ feature.text }}
                                </li>
                            </ul>
                        </div>
                        <div :class="{ 'lg:opacity-100' : userSelect == index }"
                             class="flex items-center text-red font-bold border-t border-grey py-4 pl-4 mt-auto opacity-100 lg:opacity-0">
                            <span class="hover:text-dark-red">EXPLORE</span>
                            <Icon name="arrow" class="w-4 h-4 fill-red ml-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';
import getData from '@/mixins/getData.js';
import Icon from '@/components/Icon';

export default {
    name: 'CaseStudies',

    metaInfo: {
        title: 'Explore patient case studies',
        meta: [
            {
                name: 'description',
                content: 'For Healthcare Professionals only - select a patient to explore their symptoms from a topline introduction of four patients referred for attacks of angioedema'
            },
        ]
    },

    mixins: [getData],

    components: {
        LayoutDefault,
        Icon
    },

    data() {
        return {
            userSelect: null,
            references: '<p>These fictional case simulations are considered promotional information and are provided as an educational resource for the use of Berinert<sup>&reg;</sup> 500/1500 (Berinert [IV]), which is licensed for the treatment and pre-procedure prevention of acute episodes in HAE type 1 and 2, and Berinert<sup>&reg;</sup> 2000/3000 (Berinert [SC]), licensed for the prevention of recurrent HAE attacks in adolescent and adult patients with C1-INH deficiency. CSL Behring supported the development, writing and presentation of these case simulations.</p><p>For full Prescribing Information, including indications, contraindications, warnings, precautions and adverse events, please refer to the approved product labelling. Please note that products may have different product labelling in other countries.</p><p>Please see the full EU Summary of Product Characteristics for further information.</p><p>BMI, body mass index; C1-INH, C1-esterase inhibitor; HAE, hereditary angioedema; IV, intravenous; SC, subcutaneous.</p>'
        };
    },

    methods: {
        goToUser(user) {
            this.$router.push({name: 'Treatment History', params: { name : user }});
        }
    }
};
</script>
