<template>
    <div>
        <div class="flex items-center justify-center overflow-hidden fixed z-50 fixed w-full h-full top-0 left-0">
            <div @click="closeModal()" class="absolute w-full h-full bg-grey-dark bg-opacity-40 backdrop-filter backdrop-blur-sm"></div>
            <div class="flex flex-col bg-white w-11/12 md:w-9/12 lg:w-7/12 mx-auto rounded-2xl z-50 overflow-y-auto h-3/5 md:h-auto">
                <div class="flex justify-end mt-2 mr-2 pt-2 pr-2">
                    <a class="cursor-pointer" aria-label="close" @click="closeModal()">
                        <Icon name="close" class="w-4 h-4" />
                    </a>
                </div>
                <div class="modal-content pt-8 px-8 md:px-16 pb-16 text-left">
                    <div class="flex flex-col">
                        <div class="flex flex-col items-center">
                            <slot name="icon"></slot>
                            <h2 class="text-center">
                                <slot name="title"></slot>
                            </h2>
                        </div>
                        <h5 class="text-red text-sm font-semibold uppercase mb-7">
                            <slot name="subTitle"></slot>
                        </h5>
                        <slot name="body"></slot>
                        <slot name="buttons"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon';

export default {
    name: 'Modal',
    components: {
        Icon
    },

    methods: {
        closeModal() {
            this.$emit('close');
        }
    }
};
</script>

