<template>
    <div>
        <a @click="openHomeModal()" class="home-link" :class="{'pointer-events-none': !allowModal }">
            <slot></slot>
        </a>
        <Modal v-if="isActive && allowModal" @close="closeModal()">
            <template v-slot:icon>
                <Icon name="alert" class="w-8 h-8" />
            </template>
            <template v-slot:title>
                Back to case studies
            </template>
            <template v-slot:body>
                <div class="text-center">
                    <p>Your progress will not be saved.<br>
                        You can restart the questionnaire at any time.
                    </p>
                </div>
            </template>
            <template v-slot:buttons>
                <div class="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4 mt-12">
                    <button class="alt" aria-label="close" @click="closeModal()">
                        Continue case review
                    </button>
                    <button @click="goToHomePage()">
                        Back to home
                    </button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Icon from '@/components/Icon';
import Modal from '@/components/Modal.vue';

export default {
    components: {
        Icon,
        Modal
    },

    data() {
        return {
            isActive: false
        };
    },

    computed: {
        allowModal() {
            return this.$route.name !== 'Case Studies';
        }
    },

    methods: {
        openHomeModal() {
            this.isActive = true;
        },

        closeModal() {
            this.isActive = false;
        },

        goToHomePage() {
            this.isActive = false;
            this.$router.push({
                name: 'Case Studies'
            });
        }
    }
};
</script>

<style scoped>
.home-link:hover {
    @apply cursor-pointer;
}
</style>
