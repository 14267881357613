<template>
    <div class="text-center w-full md:w-4/6">
        <h2>
            Question {{ questionId }}
            <span class="text-base font-normal">of {{ totalQuestions }}</span>
        </h2>
        <div class="font-semibold mt-3" v-html="question.questionText"></div>
        <div class="w-full md:w-3/5 lg:w-4/5 flex flex-col justify-center mx-auto my-auto mt-3 answer-otions">
            <div v-for="(answer, index) in question.answersComponent" :key="answer.id" class="flex answer-select">
                <input :id="answer.id"
                       :value="answer.id"
                       class="question-radio hidden"
                       name="radio-group"
                       type="radio"
                       v-model="formResults"
                       @input="orderNumberAnswered(index+1)"
                >
                <label :for="answer.id"
                       class="question-radio-label"
                       v-html="answer.answerText"
                       @mouseover="isHovering = true"
                       @mouseout="isHovering = false"
                       :class="{'hover-label': isHovering }"
                >
                </label>
            </div>
        </div>
        <div class="flex justify-center space-x-4 mt-12">
            <skip-modal :question="questionId" :name="name"></skip-modal>
            <button
                class="submit order-2"
                :class="{ 'cursor-not-allowed': answerSelected }"
                @click="submitQuestion()"
                :disabled="answerSelected"
            >
                Submit
            </button>
        </div>
    </div>
</template>

<script>
import question from '@/mixins/question.js';
import SkipModal from '@/components/SkipModal';

export default {
    components: {
        SkipModal
    },

    mixins: [question]
};
</script>
