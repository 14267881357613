<script>
export default {
    props: {
        action: String,
        category: String,
        label: String
    },
    render () {
        return null;
    },

    methods: {
        track() {
            this.$gtag.event(this.action, {
                'event_category': this.category,
                'event_label': this.label,
                'value': ''
            });

        }
    }
};
</script>
