<template>
    <layout-default>
        <div class="flex flex-col justify-center items-center mt-16">
            <div class="flex flex-col w-full lg:w-10/12 justify-center items-center text-center space-y-8">
                <h1>Whoops!</h1>
                <p>We’re sorry, we can’t find the page you’re looking for.</p>
                <router-link
                    :to="{ name: 'Case Studies' }"
                    class="button">
                    Explore the case studies
                </router-link>
            </div>
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault';

export default {
    name: 'NotFound',

    components: {
        LayoutDefault
    },

    metaInfo: {
        title: '404 - Page not found',
        meta: [
            {
                name: 'description',
                content: 'We’re sorry, we can’t find the page you’re looking for'
            },
        ]
    }
};
</script>
