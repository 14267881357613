import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCookies from 'vue-cookies';
import ExternalLink from '@/components/ExternalLink';
import Gtag from '@/components/Gtag';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueMeta);
Vue.component('ExternalLink', ExternalLink);
Vue.component('Gtag', Gtag);

// Google Analytics
let cookieDomain = window.location.hostname;
let windowProtocol = window.location.protocol;
let cookieFlags;

if (windowProtocol == 'https:') {
    cookieFlags = 'Secure';
}

Vue.use(VueGtag, {
    config: {
        id: 'UA-7437520-195',
        params: {
            anonymize_ip: true,
            cookie_domain: cookieDomain,
            cookie_flags: cookieFlags
        }
    },
    enabled: true
}, router);

router.beforeEach((to, from, next) => {
    let professionalConfirmed = Boolean(Vue.$cookies.get('cslProfessionalConfirmed'));
    if (to.path !== '/' && !professionalConfirmed) {
        next('/');
    } else {
        next();
    }

    //resetting body classes
    document.body.className = '';
    if (['HomeConfirmation', 'Welcome'].includes(to.name)) {
        document.body.classList.add('home-bg');
    } else if (['Overview', 'Summary'].includes(to.name)) {
        document.body.classList.add('case-studies-other-bg');
    } else if (['Case Studies', 'Not Found', 'Question', 'Result'].includes(to.name)) {
        document.body.classList.add('questions-bg');
    } else if (['Treatment History', 'Reason for presentation'].includes(to.name)) {
        document.body.classList.add('case-studies-intro-bg');
    } else {
        document.body.classList.add('');
    }
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
